.screen2BottomCerter {
  width: 340px;
  height: 148px;
  padding: 17px 16px 0;
}
.screen2BottomCerter .matchPageItem {
  height: 119px;
}
.screen2BottomCerter .matchPageItem .matchItem {
  color: rgba(255, 255, 255, 0.5);
  font-size: 12px;
}
.screen2BottomCerter .matchPageItem .matchItem .matchName {
  color: rgba(255, 255, 255, 0.74);
  margin-bottom: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.screen2BottomCerter .matchPageItem .matchItem .matchDegree {
  color: #ffffff;
}
