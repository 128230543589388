.screen3LeftBottom {
  position: relative;
  padding: 23px 27px 0;
}
.screen3LeftBottom .listItem .titleArea {
  display: flex;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 11px;
}
.screen3LeftBottom .listItem .titleArea .listOrder {
  background: linear-gradient(90deg, #D2E7FF, #2B7CF0);
  opacity: 0.54;
  height: 18px;
  width: 50px;
  text-align: center;
  border-radius: 4px;
  font-size: 12px;
  margin-right: 14px;
}
.screen3LeftBottom .listItem .titleArea .listOrderItem {
  color: #fff;
}
.screen3LeftBottom .listItem .dataArea {
  display: flex;
  font-size: 12px;
  justify-content: space-between;
  color: rgba(255, 255, 255, 0.74);
}
.screen3LeftBottom .listItem .dataArea .dataValue {
  color: #ffffff;
  margin-left: 9px;
}
