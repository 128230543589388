.screen1LeftBottom {
  width: 402px;
  height: 248px;
  padding: 21px;
  position: relative;
}
.screen1LeftBottom .typeClickArea {
  position: absolute;
  width: 68px;
  height: 18px;
  cursor: pointer;
}
