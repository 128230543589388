header {
  position: relative;
}
header .header_right {
  color: #ffffff;
  font-size: 12px;
  width: 110px;
  height: 13px;
  position: absolute;
  top: 24px;
  right: 203px;
}
