.wrapper2 {
  width: 1280px;
  background: url("../../assets/image/qidiImg/screen2Bg.png");
  color: rgba(255, 255, 255, 0.7);
}
.wrapper2 .screen2_content {
  height: 720px;
  position: relative;
}
.wrapper2 .screen2_content .title {
  font-size: 14px;
  color: #fff;
}
.wrapper2 .screen2_content .roomNamePositon {
  text-align: center;
  font-size: 12px;
  line-height: 24px;
  width: 125px;
  height: 53px;
  position: absolute;
  background: no-repeat url("../../assets/image/qidiImg/activityProvinceBg.png");
  background-size: cover;
}
.wrapper2 .screen2_content .roomNamePositon[isactivity="true"] {
  color: #ffffff;
}
.wrapper2 .screen2_content .linePosition {
  position: absolute;
  width: 1px;
  height: 60px;
  background-color: rgba(0, 252, 255, 0.42);
}
.wrapper2 .screen2_content .dotStylePositon {
  position: absolute;
  width: 8px;
  height: 8px;
  cursor: pointer;
  background-color: #ffffff;
  border-radius: 50%;
}
.wrapper2 .screen2_content .dotStylePositon[isactivity="true"] {
  width: 20px;
  height: 20px;
  border: 6px solid #7592DC;
  animation-fill-mode: both;
  animation: bounce 2s linear infinite normal;
}
.wrapper2 .screen2_content .content_center {
  width: 344px;
  height: 100px;
  position: absolute;
  top: 60px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-content: center;
  flex-wrap: wrap;
  justify-content: space-between;
}
.wrapper2 .screen2_content .content_center .center_item {
  width: 100px;
  height: 78px;
  margin-top: 10px;
  text-align: center;
  position: relative;
  font-size: 12px;
}
.wrapper2 .screen2_content .content_center .center_item .title {
  margin: 0 auto;
  width: 78px;
  margin-top: 10px;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.7);
}
.wrapper2 .screen2_content .content_center .center_item .text {
  position: absolute;
  bottom: 1px;
  left: 50%;
  transform: translateX(-50%);
  color: #ffffff;
  font-size: 20px;
}
.wrapper2 .screen2_content .left {
  float: left;
  width: 402px;
  height: 617px;
  position: absolute;
  top: 58px;
  left: 49px;
}
.wrapper2 .screen2_content .left .left_top {
  width: 100%;
  height: 246px;
  position: absolute;
  left: 0;
  top: 0;
}
.wrapper2 .screen2_content .left .left_top .title {
  height: 14px;
  margin-top: 11px;
  margin-left: 26px;
  margin-bottom: 31px;
}
.wrapper2 .screen2_content .left .left_top .info {
  height: 196px;
}
.wrapper2 .screen2_content .left .left_top .info .ant-table-body > table {
  border-collapse: collapse;
  width: 96%;
  margin: 0 auto;
}
.wrapper2 .screen2_content .left .left_top .info .ant-table-tbody > tr > td {
  color: #eee;
}
.wrapper2 .screen2_content .left .left_top .info .ant-table-thead > tr > th {
  color: #ffffff;
  background-color: #133f89;
}
.wrapper2 .screen2_content .left .left_top .info .ant-table-thead > tr > th div {
  text-align: left;
}
.wrapper2 .screen2_content .left .left_top .info .ant-table-thead > tr > th,
.wrapper2 .screen2_content .left .left_top .info .ant-table-tbody > tr > td {
  padding: 8px 8px;
  border: 0;
  font-size: 12px;
  cursor: default;
}
.wrapper2 .screen2_content .left .left_top .info .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) {
  background: #307bff;
}
.wrapper2 .screen2_content .left .left_top .info .clickRowStyl {
  background: #307bff;
}
.wrapper2 .screen2_content .left .left_bottom {
  width: 100%;
  height: 362px;
  position: absolute;
  left: 0;
  bottom: 0;
}
.wrapper2 .screen2_content .left .left_bottom .title {
  height: 14px;
  margin-top: 8px;
  margin-left: 26px;
  margin-bottom: 22px;
}
.wrapper2 .screen2_content .left .left_bottom .info {
  width: 402px;
  height: 318px;
  margin-top: 40px;
  margin-left: 20px;
}
.wrapper2 .screen2_content .right {
  width: 402px;
  height: 620px;
  position: absolute;
  top: 57px;
  right: 46px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.wrapper2 .screen2_content .right .item {
  width: 402px;
  height: 199px;
}
.wrapper2 .screen2_content .right .item .title {
  height: 34px;
  text-align: right;
  margin-right: 33px;
  line-height: 34px;
  margin-bottom: 12px;
}
.wrapper2 .screen2_content .right .item:nth-child(1) {
  margin-top: 9px;
}
.wrapper2 .screen2_content .right .item:nth-child(1) .info {
  height: 164px;
  background: url("../../assets/image/qidiImg/项目扶持情况.png") no-repeat;
  margin-left: 10px;
  margin-top: 18px;
}
.wrapper2 .screen2_content .right .item:nth-child(2) .title {
  padding-top: 10px;
}
.wrapper2 .screen2_content .right .item:nth-child(2) .info {
  height: 148px;
  background: url("../../assets/image/qidiImg/园内企业审批状况.png") no-repeat;
  margin-left: 15px;
  margin-top: 29px;
}
.wrapper2 .screen2_content .right .item:nth-child(3) .title {
  padding-top: 6px;
}
.wrapper2 .screen2_content .right .item:nth-child(3) .info {
  height: 148px;
  margin-top: 5px;
  padding-top: 10px;
}
.wrapper2 .screen2_content .right .item:nth-child(3) .info div {
  height: 50%;
  font-size: 12px;
  padding-left: 12px;
}
.wrapper2 .screen2_content .right .item:nth-child(3) .info div:nth-child(1) {
  padding-top: 8px;
}
.wrapper2 .screen2_content .right .item:nth-child(3) .policyTitleName {
  color: rgba(255, 255, 255, 0.74);
}
.wrapper2 .screen2_content .right .item:nth-child(3) .policySource {
  color: rgba(255, 255, 255, 0.5);
}
.wrapper2 .screen2_content .bottom {
  width: 340px;
  height: 188px;
  position: absolute;
  bottom: 48px;
  left: 50%;
  transform: translateX(-50%);
}
.wrapper2 .screen2_content .bottom .title {
  height: 14px;
  margin-top: 8px;
  margin-left: 26px;
  margin-bottom: 17px;
}
.line {
  margin: 0 auto;
  display: block;
  width: 88%;
  height: 1px;
  background: #0b2b6b;
}
