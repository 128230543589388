.myTable table {
  border-collapse: collapse;
  width: 96%;
  margin: 0 auto;
}
.myTable tr th {
  color: #ffffff;
}
.myTable tr th div {
  text-align: left;
}
.myTable tr th,
.myTable tr td {
  padding: 6px 8px !important;
  border: 0 !important;
  font-size: 12px !important;
}
.myTable tr:hover {
  cursor: pointer !important;
}
.clickRowStyl {
  background-color: #307bff;
}
