.screen3RightMiddle {
  padding: 11px;
}
.screen3RightMiddle .zoneImgShowArea {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
}
.screen3RightMiddle .zoneImgShowArea .imgShowArea {
  position: relative;
  width: 186px;
  height: 126px;
}
.screen3RightMiddle .zoneImgShowArea .imgShowArea .imgShowAreaMask {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 186px;
  height: 126px;
}
.screen3RightMiddle .zoneImgShowArea .imgShowArea .imgShowAreaMask[offline="true"] {
  background-color: rgba(0, 0, 0, 0.4);
}
.screen3RightMiddle .zoneImgShowArea .imgShowArea .onlineButton {
  margin: 10px;
  width: 47px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  background-color: #5246ff;
  border-radius: 4px;
}
.screen3RightMiddle .zoneImgShowArea .imgShowArea .onlineButton[offline="true"] {
  background-color: #ff4254;
}
