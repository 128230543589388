@keyframes bounce {
  from,
  50%,
  to {
    transform: translate3d(0, 0, 0) scale(1);
  }
  25% {
    transform: translate3d(0, 0, 0) scale(1.2);
  }
  75% {
    transform: translate3d(0, 0, 0) scale(0.5);
  }
}
.wrapper1 {
  width: 1280px;
  height: 720px;
  color: rgba(255, 255, 255, 0.7);
  background: url("../../assets/image/qidiImg/screen1Bg.png") no-repeat;
}
.wrapper1 .screen1_content {
  width: 1280px;
  height: 720px;
  position: relative;
}
.wrapper1 .screen1_content .title {
  font-size: 14px;
}
.wrapper1 .screen1_content .selectMap {
  width: 188px;
  height: 211px;
  border: 1px solid #fff;
  position: absolute;
  top: 50%;
  left: 50%;
}
.wrapper1 .screen1_content .selectMap .point {
  width: 8px;
  height: 8px;
  background: #ffffff;
  border-radius: 50%;
}
.wrapper1 .screen1_content .content_center {
  width: 350px;
  height: 220px;
  position: absolute;
  top: 60px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-content: center;
  flex-wrap: wrap;
  justify-content: space-around;
}
.wrapper1 .screen1_content .content_center .center_item {
  width: 160px;
  height: 78px;
  position: relative;
  margin-top: 10px;
  text-align: center;
  color: #707299;
}
.wrapper1 .screen1_content .content_center .center_item:nth-child(1) {
  margin-top: -19px;
}
.wrapper1 .screen1_content .content_center .center_item:nth-child(2) {
  margin-top: -19px;
}
.wrapper1 .screen1_content .content_center .center_item .title {
  margin: 0 auto;
  margin-top: 10px;
  color: rgba(255, 255, 255, 0.7);
  line-height: 16px;
}
.wrapper1 .screen1_content .content_center .center_item .unit {
  line-height: 16px;
  color: rgba(255, 255, 255, 0.7);
}
.wrapper1 .screen1_content .content_center .center_item .text {
  color: #ffffff;
  font-size: 24px;
  line-height: 24px;
}
.wrapper1 .screen1_content .title {
  color: #fff;
}
.wrapper1 .screen1_content .l_top {
  width: 402px;
  height: 309px;
  position: absolute;
  left: 47px;
  top: 54px;
}
.wrapper1 .screen1_content .l_top .title {
  height: 14px;
  margin-top: 21px;
  margin-left: 26px;
  margin-bottom: 25px;
}
.wrapper1 .screen1_content .l_top .info {
  width: 402px;
  height: 266px;
  padding: 10px 10px 0;
}
.wrapper1 .screen1_content .activityDot {
  position: absolute;
  left: 717px;
  top: 485px;
  animation-fill-mode: both;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #ffffff;
  border: 6px solid rgba(0, 252, 255, 0.42);
}
.wrapper1 .screen1_content .mapAreaName {
  position: absolute;
  width: 125px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  color: #ffffff;
  font-size: 12px;
  cursor: pointer;
}
.wrapper1 .screen1_content .mapAreaName[isactivity="true"] {
  color: #ffffff;
  font-size: 14px;
  background: no-repeat url("../../assets/image/qidiImg/activityProvinceBg.png");
  background-position: 50% 50%;
}
.wrapper1 .screen1_content .mapAreaDot {
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #ffffff;
  cursor: pointer;
}
.wrapper1 .screen1_content .mapAreaDot[isactivity="true"] {
  width: 20px;
  height: 20px;
  border: 6px solid #7592DC;
  animation-fill-mode: both;
  animation: bounce 2s linear infinite normal;
}
.wrapper1 .r_top {
  width: 402px;
  height: 309px;
  position: absolute;
  right: 45px;
  top: 54px;
}
.wrapper1 .r_top .title {
  height: 14px;
  margin-top: 21px;
  margin-bottom: 25px;
  margin-right: 22px;
  text-align: right;
}
.wrapper1 .r_top .info {
  height: 266px;
}
.wrapper1 .l_bottom {
  width: 402px;
  height: 309px;
  position: absolute;
  left: 47px;
  bottom: 41px;
}
.wrapper1 .l_bottom .title {
  height: 14px;
  margin-top: 22px;
  margin-left: 26px;
  margin-bottom: 21px;
}
.wrapper1 .l_bottom .info {
  width: 348px;
  height: 198px;
}
.wrapper1 .r_bottom {
  width: 402px;
  height: 309px;
  position: absolute;
  right: 45px;
  bottom: 41px;
}
.wrapper1 .r_bottom .title {
  height: 14px;
  margin-top: 23px;
  margin-bottom: 21px;
  margin-right: 20px;
  text-align: right;
}
.wrapper1 .r_bottom .info {
  height: 266px;
}
