.screen3LeftTop {
  position: relative;
}
.screen3LeftTop .screen3LeftTop-topArea {
  padding: 20px 30px 0;
  display: flex;
  justify-content: space-between;
}
.screen3LeftTop .titleArea {
  display: flex;
  letter-spacing: 2px;
  font-size: 12px;
  margin-bottom: 9px;
  line-height: 18px;
}
.screen3LeftTop .titleArea img {
  margin-right: 6px;
}
.screen3LeftTop .count {
  font-size: 16px;
  color: #ffffff;
}
