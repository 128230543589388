.wrapper3 {
  width: 1280px;
  background: url("../../assets/image/qidiImg/screen3Bg.png");
  color: rgba(255, 255, 255, 0.7);
}
.wrapper3 .screen3_content {
  height: 720px;
  position: relative;
}
.wrapper3 .screen3_content .title {
  font-size: 14px;
  color: #fff;
}
.wrapper3 .screen3_content .roomNamePositon {
  text-align: center;
  width: 125px;
  height: 53px;
  line-height: 53px;
  position: absolute;
  background: url("../../assets/image/qidiImg/activityProvinceBg.png");
  background-size: cover;
}
.wrapper3 .screen3_content .roomNamePositon[isactivity="true"] {
  color: #ffffff;
}
.wrapper3 .screen3_content .linePosition {
  position: absolute;
  width: 1px;
  height: 60px;
  background-color: rgba(0, 252, 255, 0.42);
}
.wrapper3 .screen3_content .dotStylePositon {
  position: absolute;
  width: 8px;
  height: 8px;
  cursor: pointer;
  background-color: #ffffff;
  border-radius: 50%;
}
.wrapper3 .screen3_content .dotStylePositon[isactivity="true"] {
  width: 20px;
  height: 20px;
  border: 6px solid #7592DC;
  animation-fill-mode: both;
  animation: bounce 2s linear infinite normal;
}
.wrapper3 .screen3_content .content_center {
  width: 350px;
  height: 100px;
  position: absolute;
  top: 60px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-content: center;
  flex-wrap: wrap;
  justify-content: space-around;
}
.wrapper3 .screen3_content .content_center .center_item {
  width: 100px;
  height: 78px;
  margin-top: 10px;
  text-align: center;
  position: relative;
  font-size: 12px;
}
.wrapper3 .screen3_content .content_center .center_item:nth-child(3) {
  position: relative;
  left: 6px;
}
.wrapper3 .screen3_content .content_center .center_item .title {
  margin: 0 auto;
  margin-top: 10px;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.7);
}
.wrapper3 .screen3_content .content_center .center_item .text {
  position: absolute;
  bottom: 5px;
  left: 50%;
  transform: translateX(-50%);
  color: #ffffff;
  font-size: 20px;
}
.wrapper3 .screen3_content .left {
  float: left;
  width: 402px;
  height: 617px;
  position: absolute;
  top: 58px;
  left: 49px;
}
.wrapper3 .screen3_content .left .left_top {
  width: 100%;
  height: 305px;
  position: absolute;
  left: 0;
  top: 0;
}
.wrapper3 .screen3_content .left .left_top .title {
  height: 14px;
  margin-top: 14px;
  margin-left: 26px;
  margin-bottom: 22px;
}
.wrapper3 .screen3_content .left .left_top .info {
  height: 262px;
}
.wrapper3 .screen3_content .left .left_bottom {
  width: 100%;
  height: 300px;
  position: absolute;
  left: 0;
  bottom: 0;
}
.wrapper3 .screen3_content .left .left_bottom .title {
  height: 14px;
  margin-top: 12px;
  margin-left: 26px;
  margin-bottom: 22px;
}
.wrapper3 .screen3_content .left .left_bottom .info {
  width: 402px;
  height: 252px;
}
.wrapper3 .screen3_content .right {
  width: 402px;
  height: 416px;
  position: absolute;
  top: 57px;
  right: 46px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.wrapper3 .screen3_content .right .item {
  width: 402px;
  height: 199px;
}
.wrapper3 .screen3_content .right .item .title {
  height: 34px;
  text-align: right;
  margin-right: 33px;
  line-height: 34px;
  margin-bottom: 5px;
}
.wrapper3 .screen3_content .right .item .realTimeWatch {
  display: flex;
  justify-content: space-between;
}
.wrapper3 .screen3_content .right .item .zoneSelect .ant-select-selection {
  background-color: #080e4d;
  color: rgba(255, 255, 255, 0.74);
  border-radius: 0px;
  border: 1px solid rgba(27, 109, 247, 0.5);
}
.wrapper3 .screen3_content .right .item .zoneSelect .ant-select-selection .ant-select-arrow {
  color: #255ddd;
}
.wrapper3 .screen3_content .right .item:nth-child(1) {
  margin-top: 9px;
}
.wrapper3 .screen3_content .right .item:nth-child(1) .info {
  height: 164px;
  display: flex;
}
.wrapper3 .screen3_content .right .item:nth-child(1) .info .info_left {
  width: 181px;
  height: 142px;
  margin-left: 10px;
  margin-top: 10px;
}
.wrapper3 .screen3_content .right .item:nth-child(1) .info .info_left .buildingName {
  cursor: pointer;
}
.wrapper3 .screen3_content .right .item:nth-child(1) .info .info_left .buildingName[isactivity="true"] {
  background: #307bff;
}
.wrapper3 .screen3_content .right .item:nth-child(1) .info .info_left p {
  height: 20%;
  line-height: 28.4px;
  margin: 0;
  padding-left: 13px;
}
.wrapper3 .screen3_content .right .item:nth-child(1) .info .info_left p.active {
  background: linear-gradient(45deg, #307bff, #112565);
}
.wrapper3 .screen3_content .right .item:nth-child(1) .info .info_right {
  width: 181px;
  height: 142px;
  margin-left: 10px;
  margin-top: 10px;
  background-color: rgba(48, 123, 255, 0.46);
  display: flex;
}
.wrapper3 .screen3_content .right .item:nth-child(1) .info .info_right p {
  margin: 8px;
  font-size: 12px;
}
.wrapper3 .screen3_content .right .item:nth-child(1) .info .info_right .a {
  width: 50%;
}
.wrapper3 .screen3_content .right .item:nth-child(1) .info .info_right .b {
  width: 50%;
  color: #ffffff;
}
.wrapper3 .screen3_content .right .item:nth-child(2) .title {
  padding-top: 10px;
}
.wrapper3 .screen3_content .right .item:nth-child(2) .info {
  height: 148px;
}
.wrapper3 .screen3_content .right .item:nth-child(3) .title {
  padding-top: 6px;
}
.wrapper3 .screen3_content .bottom_title {
  width: 245px;
  height: 34px;
  line-height: 34px;
  text-align: right;
  position: absolute;
  right: 66px;
  bottom: 201px;
  font-size: 14px;
}
.wrapper3 .screen3_content .bottom {
  width: 764px;
  height: 148px;
  position: absolute;
  bottom: 44px;
  right: 42px;
}
.wrapper3 .screen3_content .bottom .info {
  padding: 10px;
}
