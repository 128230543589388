.screen1RightBottom {
  position: relative;
}
.screen1RightBottom .screen1RightBottom-scoreArea {
  position: absolute;
  top: 18px;
  left: 21px;
}
.screen1RightBottom .screen1RightBottom-scoreArea .screen1RightBottom-scoreArea-title {
  font-size: 12px;
  color: #fff;
  letter-spacing: 2px;
}
.screen1RightBottom .screen1RightBottom-scoreArea .screen1RightBottom-scoreArea-score {
  font-size: 18px;
  color: #ffffff;
  font-weight: bold;
}
